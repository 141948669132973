<template>
  <div id="menu-parametrizacao" v-if="getPermissionsCompleted">
    <vs-row>
      <vs-col
        vs-lg="3"
        vs-sm="12"
        v-for="(item, index) in filteredMenus"
        :key="index"
      >
        <router-link :to="{ name: item.routerName }">
          <vs-card>
            <vs-row vs-type="flex" vs-justify="center">
              <span class="icon-param">
                <i v-if="item.configIcon" class="onpoint-gear" style="position:relative; top:-45px; left:60px;" :class="item.src"></i>
                <i :class="item.icon" style="font-size:50px; color:#4b5563;"></i>
              </span>
            </vs-row>

            <vs-row vs-type="flex" vs-justify="center">
              <h3>{{ item.name }}</h3>
            </vs-row>
          </vs-card>
        </router-link>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    permissions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    getPermissionsCompleted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    exibirParametrizacaoBureausEndosso() {
      return this.permissions.includes("ExibirEndossoAniversario");
    },
    exibirParametrizacaoBureausConsulta() {
      return this.permissions.includes("ExibirBureausConsulta");
    },
    exibirParametrizacaoConsultaPJ() {
      return this.permissions.includes("ExibirConsultaPJ");
    },
    exibirParametrizacaoRenovacao() {
      return this.permissions.includes("ExibirRenovacao");
    },
    menus() {
      return [
        {
          name: "Perfil da Corretora",
          icon: "onpoint-buildings",
          routerName: "param-broker-profile",          
          exibir: true,
          configIcon:true
        },
        {
          name: "Modalidades",
          icon: "onpoint-circles-three",
          routerName: "param-modalidades",          
          exibir: true,
          configIcon:true
          
        },
        {
          name: "Consulta PJ",
          icon: "onpoint-users-three",
          routerName: "param-consulta-pj",         
          exibir: this.exibirParametrizacaoConsultaPJ,
          configIcon:true
        },
        {
          name: "Bureaus de Consulta",
          icon: "onpoint-list-magnifying-glass",
          routerName: "param-bureaus-consulta",          
          exibir: this.exibirParametrizacaoBureausConsulta,
          configIcon:false
        },
        {
          name: "Endosso",
          icon: "onpoint-files",
          routerName: "param-endosso-list",        
          exibir: this.exibirParametrizacaoBureausEndosso,
          configIcon:true
        },
        {
          name: "Renovação de Apólices",
          icon: "onpoint-key-return",
          routerName: "param-list-renovacao",
          src: "param-renovacao-list-icon",
          exibir: this.exibirParametrizacaoRenovacao,
          configIcon:false
        }
      ];
    },
    filteredMenus() {
      return this.menus.filter(item => item.exibir);
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "param-menu",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss">
#menu-parametrizacao {
  .fa-stack.fa-cog {
    color: rgba(var(--vs-primary), 1) !important;
    left: 60px;
    top: 23px;
  }
  .con-vs-card {
    border-radius: 25px !important;
  }
  .fa-stack-2x {
    color: #626262 !important;
  }
}
</style>
